<template>
  <div class="h-full source-goodsInfo-content bg-block">
    <!-- 导航栏 -->
    <NavBar title="接单确认" @clickLeft="handleRouterBack" :customClickLeft="true"> </NavBar>

    <Empty
      v-if="isInvalid"
      imgWidth="0.84rem"
      imgHeight="0.78rem"
      colorBlack
      :image="stopImg"
      description="该货源已关闭"
      @click="backSoruceList"
    >
      <template #extra>
        <Button class="mt-42" @click="backSoruceList" size="normal">返回货源大厅</Button>
      </template>
    </Empty>

    <div class="h-full pt-12 bg-block pb-84" v-else>
      <div class="mb-12 bg-white">
        <div class="h-48 px-24 font-500 title text-18 leading-48 text-main">承运信息</div>
        <div class="px-24 van-hairline--top">
          <van-field
            @click="showPopup(1)"
            required
            readonly
            v-model="selectTruckRef.carLicensePlate"
            input-align="right"
            right-icon="arrow"
            label="驾驶车辆"
            :rules="[{ require: '', message: '必填信息不能为空' }]"
            label-class="label-class"
          />
        </div>
      </div>

      <div class="mb-12 bg-white">
        <div class="h-48 px-24 font-500 title text-18 leading-48 text-main van-hairline--bottom">收款信息</div>
        <div class="px-24">
          <van-field
            @click="showPopup(2)"
            required
            readonly
            input-align="right"
            right-icon="arrow"
            label="收款信息"
            :rules="[{ require: '', message: '必填信息不能为空' }]"
            label-class="label-class"
          >
            <template v-slot:input>
              <div v-if="!selectCarrierRef.bankAccount">请选择收款信息</div>
              <div v-else>
                <div class="lh-20 font-16">{{ selectCarrierRef.bankAccount }}</div>
                <div class="font-12 lh-12 text_muted">{{ selectCarrierRef.bankName }} {{ selectCarrierRef.name }}</div>
              </div>
            </template>
          </van-field>
        </div>
      </div>

      <div class="px-24 pb-16 bg-white">
        <div class="h-48 font-500 title text-18 leading-48 text-main">单车装货规范</div>
        <div class="flex py-16 bg-block rounded-4">
          <div>
            <div class="flex flex-col flex-1 van-hairline--right px-16">
              <span class="text-muted text-14">装货重量</span>
              <span class="mt-12 text-main text-16">
                {{ showNumberBySets('weight', sourceRef)['weight'] }}
                {{ showNumberBySets('weight', sourceRef)['weightUnit'] }}
              </span>
            </div>
            <div class="px-8 text-10 text-muted">以实际装卸重量为准</div>
          </div>
          <div class="flex flex-col flex-1 van-hairline--right px-16">
            <span class="text-muted text-14">装货体积</span>
            <span class="mt-12 text-main text-16">
              {{ showNumberBySets('volume', sourceRef) || '--' }}
              {{ sourceRef.driverShippingBillGoodsVO && sourceRef.driverShippingBillGoodsVO.volume ? '方' : '' }}
            </span>
          </div>
          <div class="flex flex-col flex-1 px-16">
            <span class="text-muted text-14">装货件数</span>
            <span class="mt-12 text-main text-16">
              {{ number }}
              {{ sourceRef.driverShippingBillGoodsVO && sourceRef.driverShippingBillGoodsVO.number ? '件' : '' }}
            </span>
          </div>
        </div>
      </div>
      <div class="px-24 pb-16 bg-white">
        {{ pricePlanText }}
      </div>
    </div>

    <div class="btn-box" @click="handleReveice" v-if="!isInvalid">
      <Button type="primary" size="large" :loading="loading" loading-text="接单中...">确认接单</Button>
    </div>
    <Popup v-model="slectTruckVisible" position="bottom">
      <!-- <Picker
        show-toolbar
        :columns="truckListRef"
        @confirm="onTruckConfirm"
        :default-index="0"
        value-key="carLicensePlate"
        @cancel="slectTruckVisible = false"
      /> -->
      <PopupLayout
        :type="popupTypeRef"
        @onConfirm="slectTruckVisible = false"
        close-on-popstate
        :close-on-click-overlay="false"
      >
        <!-- <keep-alive> -->
        <component :is="popupName"></component>
        <!-- </keep-alive> -->
      </PopupLayout>
    </Popup>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/index';
import { PageEnum } from '@/enums/pageEnum';
import { Button, Cell, Popup, Picker, Toast, Dialog } from 'vant';
import { defineComponent, ref, onBeforeMount, computed } from '@vue/composition-api';
import { useTruckStore } from '@/store/truck';
import { showNumberBySets } from '../constants';
import { useUserStore } from '@/store/user';
import { useSourceStore } from '@/store/source';
import PopupLayout from '../components/PopupLayout.vue';
import PopupVehicle from '../components/PopupVehicle.vue';
import PopupAccount from '../components/PopupAccount.vue';
import ClientApp from '@/client/index';
import { selectDriverPayeeApi } from '@/api/driver';
import { verifyBlackAPi, getPlatformAndCompanyAuditConfig } from '@/api/mine';
import { useRouter } from '@/utils/compsitionHack';
import { getPriceRule } from './utils';

// 再拉一单的code
const ActionTypes = {
  TakeTheOrderAgain: '100'
};

export default defineComponent({
  name: 'Source',
  components: { NavBar, Button, Cell, Popup, Picker, PopupLayout, PopupAccount, PopupVehicle, Dialog },
  setup(_, ctx) {
    const loading = ref(false);
    // 车辆列表
    const truckStore = useTruckStore();
    const sourceStore = useSourceStore();
    const userStore = useUserStore();

    sourceStore.updateInfo({ payeeId: 0 });
    const truckListRef = computed(() => truckStore.trucks);
    const sourceRef = computed(() => sourceStore.sourceDetail);
    const selectCarrierRef = computed(() => sourceStore.selectCarrier);
    const selectTruckRef = computed(() => sourceStore.selectTruck);
    const slectTruckVisible = ref(false);
    const authSettingRef = ref(null);

    const disabledPayeeList = ref(false);

    // =======承运人账户相关================
    const popupTypeRef = ref(1); // 1 车辆  2 账户
    const showPopup = type => {
      const sourceInfo = sourceRef.value;
      if (type === 2 && sourceInfo.carrierInfoDTO?.bankCardNumber) return;
      if (type === 2 && disabledPayeeList.value) return;
      slectTruckVisible.value = true;
      popupTypeRef.value = type;
    };
    const popupName = computed(() => {
      if (popupTypeRef.value === 1) return 'PopupVehicle';
      if (popupTypeRef.value === 2) return 'PopupAccount';
      return '';
    });
    // ===================================

    const number = computed(() => {
      const sourceInfo = sourceRef.value;
      if (sourceInfo.publishType === 0) {
        return sourceInfo.driverShippingBillGoodsVO.number || '--';
      } else {
        return sourceInfo.numberLimit || '--';
      }
    });

    const stopImg = require('@/assets/source-close.png');
    const isInvalid = ref(false);

    const pricePlanText = ref('');
    const isReviewOpen = userStore.showReviewKeys;
    const getPricePlanTest = res => {
      if (isReviewOpen) {
        console.log('PricePlan:');
        pricePlanText.value = getPriceRule(res.pricePlanDTO, res.companyWeightUnit);
        console.log(pricePlanText.value);
      }
    };

    onBeforeMount(async () => {
      // selectDriverPayee();
      const { query } = ctx.root.$router.currentRoute;
      const res = await sourceStore.querySourceDetail({
        // 拉取详情
        ...query,
        shippingBillId: query.id
      });
      if (!res.data && res.message.includes('货源已失效')) {
        isInvalid.value = true;
        return;
      }

      getPricePlanTest(res.data);

      const authSetting = await getPlatformAndCompanyAuditConfig({ companyId: res.data.companyId });
      authSettingRef.value = authSetting?.data;
      await truckStore.queryUsersTruck({
        auditStatus: authSettingRef.value === 1 ? 2 : null
      });
      if (!selectTruckRef?.value?.truckId) {
        truckStore.trucks?.[0] && sourceStore.updateSelectTruck(truckStore.trucks[0]);
      }

      let payeeInfo = null;
      let bankInfo = null;
      if (res.data?.carrierInfoDTO?.driverLinkedPayee) {
        const result = await selectDriverPayeeApi({ driverId: userStore.driverId, companyId: res.data.companyId });
        payeeInfo = result?.data;
      }
      if (payeeInfo) {
        disabledPayeeList.value = true;
        const { name, accountNo, bankDeposit, id, idCard } = payeeInfo;
        bankInfo = {
          bankAccount: accountNo,
          payeeId: id,
          name: name,
          idCard: idCard,
          bankName: bankDeposit
        };
      } else if (res.data?.carrierInfoDTO?.payeeId) {
        const { bankAccountName, bankCardNumber, bankDeposit, payeeId, payeeIdCard } = res.data?.carrierInfoDTO;
        bankInfo = {
          bankAccount: bankCardNumber,
          payeeId: payeeId,
          name: bankAccountName,
          idCard: payeeIdCard,
          bankName: bankDeposit
        };
      }

      sourceStore.updateInfo(bankInfo || {});

      await sourceStore.queryCarrierList();
    });

    // const onTruckConfirm = e => {
    //   sourceStore.updateSelectTruck(e);
    //   nextTick(() => {
    //     slectTruckVisible.value = false;
    //   });
    // };

    const handleRouterBack = () => {
      // 重置状态
      sourceStore.updateInfo({ payeeId: 0 });

      // 添加了再来一单逻辑
      const { actionType, environment } = ctx.root.$router.currentRoute.query;
      if (actionType === ActionTypes.TakeTheOrderAgain) {
        if (environment === 'native') {
          // 直接关闭页面
          ClientApp.finish();
        } else {
          // 从详情过来，直接回退
          ctx.root.$router.back();
        }
        return;
      }

      // 无效的货单，或者是直接从货源列表点立即接单按钮进来的
      if (isInvalid.value || environment === 'native') {
        ClientApp.jumpNativePage(1); // 跳转到货源列表;
      } else {
        // let query = {
        //   id: ctx.root.$router.currentRoute.query.id
        // };
        // if (ctx.root.$router.currentRoute.query.transportBillOperateSource) {
        //   query.transportBillOperateSource = ctx.root.$router.currentRoute.query.transportBillOperateSource;
        // }
        // ctx.root.$router.push({
        //   path: PageEnum.SOURCE_DETAIL,
        //   query
        // });
        // 返回到详情
        ctx.root.$router.back();
      }
    };

    const handleJumpCarrierSelect = () => {
      const { $router } = ctx.root;
      $router.push({
        path: PageEnum.SOURCE_CARRIER_SELECT,
        query: {
          id: $router.currentRoute.query.id
        }
      });
    };

    const checkBlack = async () => {
      try {
        const resDriverBlack = await verifyBlackAPi({
          type: 0,
          infoKeys: [userStore?.driverAuthVO?.idCard]
        });
        if (resDriverBlack?.data?.[0]?.isBlack) {
          Dialog.confirm({
            message: '您在当前平台黑名单中，限制开展新业务',
            showConfirmButton: false,
            cancelButtonText: '确定'
          });
          return true;
        }

        const resCarBlack = await verifyBlackAPi({
          type: 2,
          infoKeys: [selectTruckRef.value.carLicensePlate]
        });
        if (resCarBlack?.data?.[0]?.isBlack) {
          Dialog.confirm({
            title: '车辆被禁用',
            message: '车辆在当前平台黑名单中，限制开展新业务',
            confirmButtonText: '查看详情',
            cancelButtonText: '确定'
          }).then(() => {
            Dialog.confirm({
              message: resCarBlack?.data?.[0]?.reason,
              showConfirmButton: false,
              cancelButtonText: '确定'
            });
          });
          return true;
        }
      } catch (e) {
        //
      }
    };

    const router = useRouter();
    const showDriverAuthFailModal = () => {
      const { driverId } = userStore;
      Dialog.confirm({
        message: '司机审核未通过，无法接单',
        confirmButtonText: '点击认证',
        closeOnClickOverlay: true
      })
        .then(() => {
          router.push({
            path: userStore.driverId ? PageEnum.BASE_DRIVER_AUTH_RESULT : PageEnum.BASE_DRIVER_AUTH,
            query: {
              needBack: driverId ? 1 : 0
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    };

    const showDriverAuthModal = () => {
      Dialog.confirm({
        message: '司机暂未认证，无法接单！',
        confirmButtonText: '点击认证',
        closeOnClickOverlay: true
      })
        .then(() => {
          router.push(PageEnum.BASE_DRIVER_AUTH);
        })
        .catch(err => {
          console.log(err);
        });
    };

    const checkAuthSetting = () => {
      const { driverAuthVO } = userStore;
      const { driverId, authStatus } = driverAuthVO;
      if (driverId) {
        if (authSettingRef.value === 1 && authStatus !== 2) {
          showDriverAuthFailModal();
          return true;
        }
      } else {
        showDriverAuthModal();
        return true;
      }
    };

    const handleReveice = async () => {
      const { $router } = ctx.root;
      if (selectTruckRef?.value && selectCarrierRef?.value?.bankAccount) {
        if (checkAuthSetting()) return;
        const { bankAccount, payeeId, name, idCard, bankName } = selectCarrierRef.value;
        // 没有数据为空的情况
        loading.value = true;
        let query = {
          ...selectTruckRef.value,
          shippingBillId: $router.currentRoute.query.id,
          bankAccountName: name,
          bankCardNumber: bankAccount,
          bankDeposit: bankName,
          payeeIdCard: idCard,
          payeeId
        };
        const resBlack = await checkBlack();
        if (resBlack) return;

        if ($router.currentRoute.query.transportBillOperateSource) {
          query.transportBillOperateSource = $router.currentRoute.query.transportBillOperateSource;
        }
        // if (query.transportBillOperateSource == '100') {
        //   //从列表货源大厅点再来一单进来的
        //   query.transportBillOperateSource = 5;
        // }
        sourceStore.queryTakingOrder(
          query,
          () => {
            // 重置状态
            sourceStore.updateInfo({ payeeId: 0 });

            // $router.push(PageEnum.BASE_SOURCE);
            ClientApp.jumpNativePage(0, 1); // 接单成功后跳转到 运单首页
          },
          () => {
            // 重置状态
            // sourceStore.updateInfo({ payeeId: 0 });

            loading.value = false;
          }
        );
      } else {
        Toast.fail('请选择收款信息');
      }
      // 执行接单操作
    };

    const backSoruceList = () => {
      ClientApp.jumpNativePage(1); // 跳转到货源列表;
    };

    return {
      loading,
      sourceRef,
      selectTruckRef,
      truckListRef,
      selectCarrierRef,
      handleRouterBack,
      slectTruckVisible,
      // onTruckConfirm,
      handleJumpCarrierSelect,
      handleReveice,
      showNumberBySets,

      showPopup,
      popupTypeRef,
      popupName,
      number,

      stopImg,
      isInvalid,
      backSoruceList,
      pricePlanText
    };
  }
});
</script>
<style lang="less">
.source-goodsInfo-content {
  min-height: 100vh;

  /deep/ .van-cell--required .van-field__label::after {
    font-size: 0.18rem;
  }

  .van-cell {
    flex: 1;
    padding: 0;
    line-height: 0.4rem;
  }

  .van-field__control {
    @apply text-16;
  }
  .van-field__control::placeholder {
    @apply text-placeholder;
    @apply text-16;
  }

  .label-class {
    font-size: 0.16rem;
    color: #999;
  }
  // 覆盖vant样式
  // .content {
  //   overflow: hidden;
  //   background: #fff;
  //   .list {
  //     height: 0.4rem;
  //     line-height: 0.4rem;
  //     display: flex;
  //     justify-content: space-between;
  //     border-bottom: 0.01rem solid rgba(188, 188, 188, 0.5);
  //     font-size: 0.12rem;
  //     color: #878787;
  //     input {
  //       border: none;
  //       color: #1d1d1d;
  //       font-size: 0.16rem;
  //     }
  //   }
  // }
  .van-cell {
    padding: 0;
    padding-left: 0.12rem;
    line-height: 0.48rem;

    input {
      font-size: 0.16rem;
      color: #333;
    }
  }
}
</style>
